
























import { Component, Vue } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'
import API from '@/api/index.js'
import Util from '@/utils/index.js'
import { Dialog } from 'vant'
import Agreement from '../../common/agreement/agreement.vue'
import InputBox from '../../common/input-box/inputBox.vue'

Vue.use(Dialog)

@Component({
    components: {
        Agreement,
        InputBox
    }
})
export default class PhoneLogin extends myMixins {
    isAgree = true
    vertificationCode = '' // 验证码
    phone = this.$store.state.login.inputPhone
    canClick = false // 按钮是否可点击
    action = 2 // 验证码类型 1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机

    mounted() {
        this.canClick = Util.checkPhone(this.phone) && this.isAgree
    }

    statusChange(status: boolean) {
        this.isAgree = status
        this.canClick = Util.checkPhone(this.phone) && this.isAgree
    }

    valueChange(params: Param) {
        const { name, val } = params
        this[name] = val
        // 记录用户输入的手机号
        if (Util.checkPhone(this.phone)) {
            this.$store.commit('login/updateInputPhone', this.phone)
        }
        this.canClick = Util.checkPhone(this.phone) && this.isAgree
    }

    async checkPhoneAccount() {
        if (this.canClick) {
            const res = await API.checkPhoneStatus({ phone: this.phone })
            if (res && res.data && res.data.errorCode === 200) {
                const { status } = res.data.data
                if (status === 1) {
                    // 有账号有微信直接登录
                    this.action = 2
                    this.sendYzm()
                } else if (status === 2) {
                    // 走登录 在登录时绑定微信tokenID
                    this.action = 2
                    this.sendYzm()
                } else if (status === 3) {
                    //  未注册 走注册流程
                    this.action = 1
                    this.sendYzm()
                }
            }
        }
    }

    // 手机登录发送验证码
    async sendYzm() {
        this.$store.commit('login/updateTimeNumber', -1)
        this.getYZM()
        const res = await API.sendForYzm({
            mobile: this.phone,
            action: this.action
        })
        if (res && res.data && res.data.errorCode === 200) {
            this.$store.commit('login/updateTimePhone', this.phone)
            this.$store.commit('login/updateTimeAction', this.action)
            this.$nextTick(() => {
                this.routerChange('verificationCode')
            })
        } else if (res.data.errorMsg && res.data.errorMsg.includes('发送上限')) {
            this.routerChange('passwordLogin')
        }
    }
}
